import React, { ReactElement } from "react";
import colors from "@styles/variables/moveMEoutVariables.module.scss";

import StressFreeIcon from "../icons/stressFreeIcon";
import PackingInShortTimeIcon from "../icons/packingInShortTimeIcon";
import AvoidInjuryIcon from "../icons/avoidInjuryIcon";
import MovingQuicklyIcon from "../icons/movingQuicklyIcon";
import FreeEstimateIcon from "../icons/freeEstimateIcon";
import RightToolsIcon from "../icons/rightToolsIcon";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Stress-Free Move",
        description:
            "Renting a truck on your own is not an easy task as you will not be able to estimate the right truck size that will accommodate your furniture and belongings.",
        icon: <StressFreeIcon />,
    },
    {
        title: "Quick & Efficient Packing",
        description:
            "They will know which packing materials you will need and how to pack fragile items like antiques, glassware, etc. Also, they will correctly label the packages so that you know which package contains what when they are delivered to you.",
        icon: <PackingInShortTimeIcon />,
    },
    {
        title: "Avoid Potential Injury",
        description:
            "As the company will do all the moving, packing, and unpacking, you can avoid any frustration or body pain. So you just kick back and relax!",
        icon: <AvoidInjuryIcon />,
    },
    {
        title: "Quick Moving",
        description:
            "If you have to make an urgent move to a new location, there are moving companies who can offer you quick moving services to have your move facilitated in a short time.",
        icon: <MovingQuicklyIcon />,
    },
    {
        title: "Free Estimate",
        description:
            "Moving companies will provide you with an all-inclusive price estimate, so you won't have to worry about arranging and planning everything.",
        icon: <FreeEstimateIcon />,
    },
    {
        title: "They Have the Right Tools",
        description:
            "A professional moving company will bring with them not only their experienced team but also all the necessary tools and equipment to pack and move everything around as needed.",
        icon: <RightToolsIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="The Advantages of Hiring a Top-Rated Moving Company"
            classes={{
                mainTitleClasses:
                    "text-center font-bold text-xl lg:text-4xl mb-14",
                innerSectionClasses: "lg:flex flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: "children:mx-auto",
                titleClasses: "font-medium text-lg lg:text-xl mt-6",
                descriptionClasses:
                    "font-light text-sm text-center w-4/5 mx-auto mt-2",
            }}
            colors={{
                mainTitleColor: colors.whyUsTitle,
                titleColor: colors.whyUsItemTitle,
                descriptionColor: colors.whyUsItemBrief,
            }}
        />
    );
}
